import { createTheme } from "@mui/material";
import { unityTheme } from "@ntpkunity/controls";

export const CustomColors = {
  primary: "#EE3B52",
  gradient: "linear-gradient(90deg, #DD2476 0%, #FF512F 100%)",
};

export const templexTheme = createTheme({
  ...unityTheme,
  palette: {
    ...unityTheme.palette,
    primary: { main: CustomColors.primary },
  },
});
