import { FC, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTheme, TablePagination } from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import ChevronDown from "@shared/assets/images/chevron-down";
import {
  Button,
  Switch,
  Box,
  Input,
  Icon,
  Menu,
  DataTable,
} from "@ntpkunity/controls";
import { ConfirmationDialog } from "shared/components";

import { styled } from "@mui/material/styles";
import { ControlAction, DialogMessages } from "@shared/constants";
import { ITemplateConfiguration, ITemplateConfigurationFilter } from "../../../templexConfiguration.types";
import { useGetTemplateConfigurationFilter, useUpdateTemplateConfiguration } from "@modules/templexConfiguration/services/configureTemplateService";


const tableOptions: any = [
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="EditIcon" />
        {ControlAction.EDIT}
      </>
    ),
    optionkey: ControlAction.EDIT,
    optionValue: {},
  },
  {
    optionText: (
      <>
        <Icon className="menu-icon" name="DeleteIcon" />
        {ControlAction.DELETE}
      </>
    ),
    optionkey: ControlAction.DELETE,
    optionValue: {},
  },
];

const select = [{ text: "Select", value: "Select" }];


const ConfigureTemplateTableComp: FC<{
  onEdit: (data: ITemplateConfiguration) => unknown;
}> = ({ onEdit }) => {
  const [popUpState, setPopUpState] = useState(false);
  const theme = useTheme();

  interface IFormInputs {
    code: string;
  }

  const [templateNameFilter, setTemplateNameFilter] = useState("");
  const [storageTypeFilter, setStorageTypeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [columnFilters, setColumnFilters] = useState(
    `page_number=${pageNumber}&page_size=${pageSize}`
  );
  const { data } = useGetTemplateConfigurationFilter(columnFilters);
  const [confirmationPopupState, setConfirmationPopupState] =
    useState<boolean>(false);
  const templateConfigurationFilterData: ITemplateConfigurationFilter = data;
  const [actionData, setActionData] = useState<ITemplateConfiguration>(undefined);
  const { mutate: updateTemplateConfiguration } = useUpdateTemplateConfiguration();


  const setQueryString = (pgNo, pgSize) => {
    let query_string: string = `page_number=${pgNo}&page_size=${pgSize}`;
    if (templateNameFilter.trimStart() != null && templateNameFilter.trimStart() != "") {
      query_string = query_string.concat(`&template_name=${templateNameFilter}`);
    }
    if (
      storageTypeFilter.trimStart() != null &&
      storageTypeFilter.trimStart() != ""
    ) {
      query_string = query_string.concat(`&storage_type=${storageTypeFilter}`);
    }
    if (statusFilter.trimStart() != null && statusFilter.trimStart() != "") {
      if (
        "enabled".startsWith(
          statusFilter.toLowerCase().slice(0, Math.max("enabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${true}`);
      } else if (
        "disabled".startsWith(
          statusFilter
            .toLowerCase()
            .slice(0, Math.max("disabled".length - 1, 1))
        )
      ) {
        query_string = query_string.concat(`&is_active=${false}`);
      } else {
        query_string = query_string.concat(`&is_active=None`);
      }
    }
    return query_string;
  };
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setColumnFilters(setQueryString(newPage, pageSize));
  };

  const handleFilter = () => {
    setPageNumber(0);
    setColumnFilters(setQueryString(0, pageSize));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPageSize(+event.target.value);
    setPageNumber(0);
    setColumnFilters(setQueryString(0, +event.target.value));
  };

  const handleSelection = (event, key, value) => {
    switch (key) {
      case ControlAction.EDIT:
        onEdit(value);
        return;
      case ControlAction.DELETE:
        setActionData(value);
        setConfirmationPopupState(true);
        return;
    }
  };

  const onDeleteConfirm = (e: any) => {
    // deleteCondition(actionData?.id, {
    //   onSuccess: (data, variables, context) => {
    //     setActionData(undefined);
    //   },
    // });
  };

  const handleEnableChange = (data: ITemplateConfiguration, event) => {
    updateTemplateConfiguration({ ...data, is_active: event.target.checked });
  };

  const handleDefaultChange = (data: ITemplateConfiguration, event) => {
    updateTemplateConfiguration({ ...data, default: event.target.checked });
  };

  return (
    <>
      <DataTable
        theme={theme}
        variant="basic"
        theadChildren={
          <>
            <TableRow>
              <TableCell>Template Name</TableCell>
              <TableCell>Storage Type</TableCell>
              <TableCell>Template URL</TableCell>
              <TableCell className="action-cell fixed-cell" />
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
            <TableRow className="filters-row">
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setTemplateNameFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={templateNameFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setStorageTypeFilter(value);
                    }}
                    onBlur={() => {
                      handleFilter();
                    }}
                    value={storageTypeFilter?.trimStart()}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    placeholder={"Search..."}
                    type="text"
                    onChange={(value) => {
                      setStatusFilter(value);
                    }}
                    value={statusFilter?.trimStart()}
                    onBlur={() => {
                      handleFilter();
                    }}
                  />
                  <Button
                    defaultBtn
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          templateConfigurationFilterData?.result &&
          templateConfigurationFilterData.result.map((templateConfiguration, index) => (
            <TableRow key={index} className="child-tr">
              <TableCell>{templateConfiguration?.template_name}</TableCell>
              <TableCell>{templateConfiguration?.storage_type}</TableCell>
              <TableCell>{templateConfiguration?.template_url}</TableCell>
              <TableCell>
                <Switch
                  theme={theme}
                  varient={"basic"}
                  switchEnabled={templateConfiguration?.is_active}
                  onChange={(event) =>
                    handleEnableChange(templateConfiguration, event)
                  }
                  label={templateConfiguration.is_active ? "Enabled" : "Disabled"}
                />
              </TableCell>
              <TableCell>
                <Switch
                  theme={theme}
                  varient={"basic"}
                  switchEnabled={templateConfiguration?.default}
                  onChange={(event) =>
                    handleDefaultChange(templateConfiguration, event)
                  }
                  label={"Default"}
                />
              </TableCell>
              <TableCell className="action-cell fixed-cell">
                <Menu
                  theme={theme}
                  options={tableOptions.map((option) => {
                    return {
                      optionText: option.optionText,
                      optionkey: option.optionkey,
                      optionValue: templateConfiguration,
                    };
                  })}
                  handleOptionClick={handleSelection}
                  render={(onMenuSelection) => (
                    <Button
                      defaultBtn
                      iconText={<Icon name="MoreIcon" />}
                      onClick={onMenuSelection}
                    />
                  )}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                />
              </TableCell>
            </TableRow>
          ))
        }
      />
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20]}
        component="div"
        count={templateConfigurationFilterData ? templateConfigurationFilterData?.total_results : -1}
        rowsPerPage={pageSize}
        page={pageNumber}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        SelectProps={{
          IconComponent: ChevronDown,
        }}
      />

      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          confirmationTitle={DialogMessages.confirmationTitle}
          confirmationText={DialogMessages.confirmationText}
          primaryBtnText={DialogMessages.deleteBtnText}
          onConfirm={onDeleteConfirm}
          setPopUpState={setConfirmationPopupState}
          icon={<Icon name="DialogDeleteIcon" />}
        />
      )}
    </>
  );
};

export default ConfigureTemplateTableComp;

