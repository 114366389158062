import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const TemplexHomePageStyle = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  ".header-wrapper": {
    display: "flex",
    position: "sticky",
    top: "0",
    width: "100%",
    alignItems: "center",
    background: "#F5F5F7",
    zIndex: 1,
    justifyContent: "space-between",
    padding: "30px 40px 20px 40px",
    img: {
      height: "70px",
      width: "auto",
      objectFit:"contain",
      cursor:"pointer"
    },
    ".header-container": {
      display: "flex",
      alignItems: "center",
      gap: 10,
      ".update-icon": {
        marginTop: "-7px",
      },
    },
  },
  ".search-wrapper": {
    width: "100%",
    borderTop: "1px solid #D8DAE5",
    borderBottom: "1px solid #D8DAE5",
    display: "flex",
    padding: "0px 40px",
    margin: "20px 0px",
    ".search-container": {
      display: "flex",
      alignItems: "center",
      position: "relative",
      width: "100%",
      input: {
        "&:focus": {
          outline: "none",
        },
        "::placeholder": { color: "rgba(0, 0, 0, 0.26)" },
        width: "100%",
        backgroundColor: "#F5F5F7",
        height: "72px",
        border: "none",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "0px 10px",
        color: "rgba(0, 0, 0, 0.26)",
      },
    },
    ".filter-container": {
      display: "flex",
      alignItems: "center",
      minWidth: "80px",
      justifyContent: "space-between",
      cursor:"pointer",
      ".sortby-text": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
        display: "flex",
        alignItems: "center",
        color: "#515466",
      },
      ".sortby-arrow": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "24px",
        color: "#515466",
      },
    },
  },
  ".templates-wrapper": {
    padding: "0px 40px 20px 40px",
    ".templates-container": {
      background: "#FFFFFF",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "24px",
      gap: "6px",
      transition: '200ms ease',
      "&:hover": {
        boxShadow: "0px 24px 24px -24px rgba(60, 0, 137, 0.25)",
      },
    },
    ".templates-container-header": {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between",
      ".template-name-text": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#12131A",
      },
      ".template-default-text": {
        minWidth: "76px",
        height: "24px",
        background: "rgba(60, 0, 137, 0.1)",
        borderRadius: " 24px",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: " 11px",
        lineHeight: " 16px",
        letterSpacing: "0.5px",
        display: " flex",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        color: "#3C0089",
      },
    },
    ".template-date": {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
      color: "#515466 ",
    },
    ".templates-container-footer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      marginTop: "24px",
      ".switch-container": {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        ".switch-status": {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          color: "#515466",
        },
      },
      ".tools-container": { display: "flex", alignItems: "center" },
    },
  },
}));
