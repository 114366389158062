import { SnackbarCloseReason, StyledEngineProvider } from "@mui/material";
import { ThemeProvider as MuiThemeProvider, useTheme } from "@mui/material/styles";
import { LoginValidatorComponent } from "@ntpkunity/controls-ums";
import { templexTheme } from "@shared/theme";
import { CustomTheme } from "@shared/theme/customTheme";
import { useEffect, useState } from "react";
import { ModalProvider } from "react-modal-hook";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./router";
import { SetupsStoreProvider } from "./store/SetupStore/SetupsStoreProvider";
import StoreProvider, { useStoreContext } from "./store/storeContext";

export default function Root(props) {
  const queryClient = new QueryClient();

  return (
    <LoginValidatorComponent isPublic={true} theme={templexTheme}>
      <QueryClientProvider client={queryClient}>
        <SetupsStoreProvider>
          <App {...props} />
          <ReactQueryDevtools initialIsOpen={false} />
        </SetupsStoreProvider>
      </QueryClientProvider>
    </LoginValidatorComponent>
  );
}

const App = () => {
  const theme = useTheme();
  const { states, actions } = useStoreContext();
  const { toastData } = states;
  const onToastClose = (
    event?: React.SyntheticEvent<any> | Event,
    reason?: SnackbarCloseReason
  ) => {
    if (reason !== "clickaway") {
      actions.setToast({ toastMessage: "", toastState: false });
    }
  };

  return (
    <>
      {(
        <>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={templexTheme}>
              <ModalProvider>
                <BrowserRouter>
                  <CustomTheme theme={theme}>
                  <Router />
                  </CustomTheme>
                </BrowserRouter>
              </ModalProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </>
      )}
    </>
  );
};
