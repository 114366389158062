import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const SectionContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  ".header-wrapper": {
    display: "flex",
    width: "100%",
    alignItems: "center",
    background: "#F5F5F7",
    zIndex: 1,
    justifyContent: "space-between",
    padding: "30px 40px 20px 40px",
    ".header-container-left": {
      display: "flex",
      alignItems: "center",
      gap: 10,
      ".text-h3": {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "36px",
        lineHeight: "48px",
        color: "#12131A",
      },
    },
    ".header-container-right": {
      display: "flex",
      alignItems: "center",
      gap: 10,
    },
  },
  ".main-container": {
    padding: "40px",
    margin: "20px 40px 20px 56px",
    background: "#FFFFFF",
    borderRadius: "16px",
    minHeight:"80vh",
  },
  ".custom-checkbox-wrap": {
    ".u-custom-control-label": {
      marginBottom: 0,
      ".MuiFormControlLabel-label": {
        color: theme.palette.grey[900],
      },
    },
  },
  ".form-btn": {
    ".btn": {
      marginTop: 32,
    },
  },
  ".u-form-group": {
    ".u-form-control": {
      "&.MuiInputBase-adornedEnd": {
        svg: {
          path: {
            stroke: theme.palette.grey[300],
          },
        },
        cursor: "pointer",
        ".MuiButtonBase-root": {
          padding: 0,
          "&:hover": {
            backgroundColor: "transparent",
          },
          ".MuiTouchRipple-root": {
            display: "none",
          },
        },
      },
    },
  },
  'input[type="password"]::-ms-reveal': {
    display: "none",
  },
  'input[type="password"]::-ms-clear': {
    display: "none",
  },
}));
