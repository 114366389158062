import { AdvanceDocumentEditor } from "@modules/templexConfiguration/components";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTheme } from "@mui/material";
import { Box, Button, Icon, Switch, Typography } from "@ntpkunity/controls";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CreateTemplatePageStyle } from "./createTemplatePageStyle";

const CreateTemplatePage: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  return (
    <>
      <CreateTemplatePageStyle theme={theme}>
        <Box theme={theme} className="header-wrapper">
          <Button
            theme={theme}
            iconText={<Icon name="IconLeftArrow" />}
            onClick={() => navigate("/dock/home")}
          />
          <Box theme={theme} className="header-container">
            <Button
              theme={theme}
              secondary
              iconText={<Icon name="MoreIcon" />}
            />
            <Button theme={theme} primary text="Save Template" onClick={()=>navigate('/dock/configure-template-storage')}/>
          </Box>
        </Box>
        <Box theme={theme} className="editor-container">
          <Box theme={theme} className="filter">
            <Box theme={theme} className="filter-wrapper">
              <Box theme={theme} className="filter-container">
                <Typography
                  theme={theme}
                  component="div"
                  className="sortby-text"
                >
                  Storage:
                </Typography>
                <KeyboardArrowDownIcon className="sortby-arrow" />
              </Box>
              <Box theme={theme} className="filter-container">
                <Typography
                  theme={theme}
                  component="div"
                  className="sortby-text"
                >
                  Default:
                </Typography>
                <KeyboardArrowDownIcon className="sortby-arrow" />
              </Box>
            </Box>
            <Box theme={theme} className="switch-container">
              <Typography
                theme={theme}
                component="div"
                className="switch-status"
              >
                {checked ? "Enabled" : "Disabled"}
              </Typography>
              <Switch
                theme={theme}
                varient={"basic"}
                checked={checked}
                onChange={() => setChecked(!checked)}
              />
            </Box>
          </Box>
          <Typography
            theme={theme}
            component="div"
            className="template-name"
            variant="h1"
          >
            Sample Template Name
          </Typography>
          <AdvanceDocumentEditor />
        </Box>
      </CreateTemplatePageStyle>
    </>
  );
};

export default CreateTemplatePage;
