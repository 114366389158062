import { styled } from "@mui/system";
import { Box, IBoxProps } from "@ntpkunity/controls";
import { CustomColors } from "./index";

export const CustomTheme = styled(
  Box,

  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  ".btn": {
    "&.btn-primary": {
      background: CustomColors.gradient,
      backgroundColor: "transparent",
      borderColor: "transparent",
      border: "none",
      "&:hover": {
        background: theme.palette.grey[900] + "!important",
        backgroundColor: theme.palette.grey[900] + "!important",
        color: [theme.palette.common.white] + "!important",
      },
      "&.with-icon:hover": {
        svg: {
          path: {
            stroke: theme.palette.common.white + "!important",
          },
        },
      },
      "&:disabled": {
        opacity: 0.5,
        cursor: "not-allowed",
        color: theme.palette.common.white + "!important",
      },
    },
  },
  ".MuiSwitch-root .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
    background: CustomColors.gradient,
    backgroundColor: "transparent",
  }
}));
