import { LayoutWithSideNav } from "@layout/index";
import {
  useGetS3Configurations,
  useSaveS3Configurations,
  useUpdateS3Configurations,
} from "@modules/templexConfiguration/services/configureTemplateStorageService";
import { IS3Configuration } from "@modules/templexConfiguration/templexConfiguration.types";
import { useTheme } from "@mui/material";
import {
  Box,
  Button,
  Grid,
  Icon,
  Input,
  Select,
  Typography,
} from "@ntpkunity/controls";
import DisableLoader from "@shared/assets/images/loader-disabled-btn.gif";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  ControlAction,
  PasswordKey,
  updateS3ConfigurationsMessage,
} from "shared";
import { useStoreContext } from "store/storeContext";
import { SectionContent } from "./configureTemplateStoragePageStyle";

const messages = {
  label: {
    templateName: "Template Name",
  },
  name: {
    s3_access_key: "s3_access_key",
    s3_secret_access: "s3_secret_access",
    s3_bucket_name: "s3_bucket_name",
  },
  validation: {},
  button: {
    save: "Save Changes",
    wait: "Wait...",
  },
  placeholder: {
    typeHere: "Type here...",
  },
  successMsg: "Saved successfully.",
};

export const ConfigureTemplateStoragePage: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showKey, setShowKey] = useState(false);
  const [showSaveS3Configurations, setShowSaveS3Configurations] =
    useState(false);
  const [actionType, setControlActionType] = useState<string>(
    ControlAction.EDIT
  );
  const { data: s3ConfigurationsData } = useGetS3Configurations();
  const {
    mutate: saveS3Configurations,
    isLoading: saveS3ConfigurationsLoading,
  } = useSaveS3Configurations();
  const {
    mutate: updateS3Configurations,
    isLoading: updateS3ConfigurationsLoading,
  } = useUpdateS3Configurations();
  const s3ConfigurationForm = useForm<IS3Configuration>();
  const { control } = useForm<any>();

  const {
    actions: { setToast },
  } = useStoreContext();

  useEffect(() => {}, []);

  useEffect(() => {
    if (
      s3ConfigurationsData !== undefined &&
      s3ConfigurationsData[0]?.tenant_id > 0
    ) {
      s3ConfigurationForm.setValue(
        "access_key_id",
        s3ConfigurationsData[0]?.access_key_id
      );
      s3ConfigurationForm.setValue(
        "secret_access_key",
        s3ConfigurationsData[0]?.secret_access_key
      );
      s3ConfigurationForm.setValue(
        "region_name",
        s3ConfigurationsData[0]?.region_name
      );
      s3ConfigurationForm.setValue(
        "bucket_name",
        s3ConfigurationsData[0]?.bucket_name
      );

      setControlActionType(ControlAction.EDIT);
    } else {
      setControlActionType(ControlAction.SAVE);
    }
  }, [s3ConfigurationsData]);

  const onS3ConfigurationsSubmit = (data: any): void => {
    if (actionType === ControlAction.SAVE) {
      saveS3Configurations(data, {
        onSuccess() {
          setShowSaveS3Configurations(false);
        },
        onError() {},
      });
    } else {
      updateS3Configurations(data, {
        onSuccess() {
          setToast({
            toastMessage: "Record Updated Successfully",
            toastState: true,
          });
          setShowSaveS3Configurations(false);
        },
        onError() {},
      });
    }
  };

  return (
    <>
      <SectionContent theme={theme} className="section-content">
        <Box theme={theme} className="header-wrapper">
          <Box theme={theme} className="header-container-left">
            <Button
              theme={theme}
              iconText={<Icon name="IconLeftArrow" />}
              onClick={() => navigate("/dock/create")}
            />
            <Typography
              theme={theme}
              variant="h3"
              component="h3"
              className="text-h3"
            >
              Storage Configurations
            </Typography>
          </Box>
          <Box theme={theme} className="header-container-right">
            <Button theme={theme} primary text="Save Configurations" />
          </Box>
        </Box>

        <Box theme={theme} className="main-container">
          <Grid theme={theme} container item spacing={3}>
            <Grid theme={theme} item xs={12} md={4}>
              <Controller
                defaultValue={"S3"}
                control={control}
                name="storage_type"
                render={({ field: { onChange, value } }) => (
                  <Select
                    theme={theme}
                    label={"Storage Type"}
                    items={[{ text: "S3", value: "S3" }]}
                    sxProps={false}
                    placeholder="Select"
                    disablePortal={false}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value}
                  />
                )}
              />
            </Grid>
          </Grid>

          <form
            onSubmit={s3ConfigurationForm.handleSubmit(
              onS3ConfigurationsSubmit
            )}
            onChange={() => setShowSaveS3Configurations(true)}
          >
            <SectionContent
              theme={theme}
              className="section-content"
              mt={{ xs: 0, md: 4 }}
              pb={4}
              mb={4}
            >
              <Grid theme={theme} container item spacing={3}>
                <Grid theme={theme} item xs={12} md={4}>
                  <Controller
                    name="access_key_id"
                    control={s3ConfigurationForm.control}
                    render={({ field }) => (
                      <Input
                        theme={theme}
                        fullWidth
                        label={"Access Key"}
                        type={showKey ? PasswordKey.Text : PasswordKey.Password}
                        placeholder={messages.placeholder.typeHere}
                        autoComplete="off"
                        onChange={(e) => {
                          setShowSaveS3Configurations(true);
                        }}
                        endAdornment={
                          <>
                            {showKey ? (
                              <Icon
                                name="IcView"
                                onClick={() => setShowKey(!showKey)}
                              />
                            ) : (
                              <Icon
                                name="IcViewOff"
                                onClick={() => setShowKey(!showKey)}
                              />
                            )}
                          </>
                        }
                        error={s3ConfigurationForm.formState.errors?.[
                          messages.name.s3_secret_access
                        ]?.message?.toString()}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid theme={theme} item xs={12} md={4}>
                  <Controller
                    name="secret_access_key"
                    control={s3ConfigurationForm.control}
                    render={({ field }) => (
                      <Input
                        theme={theme}
                        fullWidth
                        type={
                          showPassword ? PasswordKey.Text : PasswordKey.Password
                        }
                        placeholder={messages.placeholder.typeHere}
                        label="Secret Access"
                        autoComplete="off"
                        onChange={(e) => {
                          setShowSaveS3Configurations(true);
                        }}
                        endAdornment={
                          <>
                            {showPassword ? (
                              <Icon
                                name="IcView"
                                onClick={() => setShowPassword(!showPassword)}
                              />
                            ) : (
                              <Icon
                                name="IcViewOff"
                                onClick={() => setShowPassword(!showPassword)}
                              />
                            )}
                          </>
                        }
                        error={s3ConfigurationForm.formState.errors?.[
                          messages.name.s3_secret_access
                        ]?.message?.toString()}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid theme={theme} item xs={12} md={4}>
                  <Controller
                    name="bucket_name"
                    control={s3ConfigurationForm.control}
                    render={({ field }) => (
                      <Input
                        theme={theme}
                        fullWidth
                        type="text"
                        autoComplete="off"
                        placeholder={messages.placeholder.typeHere}
                        label="Bucket Name"
                        onChange={(e) => {
                          setShowSaveS3Configurations(true);
                        }}
                        error={s3ConfigurationForm.formState.errors?.[
                          messages.name.s3_bucket_name
                        ]?.message?.toString()}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid theme={theme} item xs={12} md={4}>
                  <Controller
                    name="region_name"
                    control={s3ConfigurationForm.control}
                    render={({ field }) => (
                      <Input
                        theme={theme}
                        fullWidth
                        type="text"
                        autoComplete="off"
                        placeholder={messages.placeholder.typeHere}
                        label="Region Name"
                        onChange={(e) => {
                          setShowSaveS3Configurations(true);
                        }}
                        error={s3ConfigurationForm.formState.errors?.[
                          messages.name.s3_bucket_name
                        ]?.message?.toString()}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {showSaveS3Configurations && (
                <Box theme={theme} className="form-btn">
                  <Button
                    type="submit"
                    theme={theme}
                    primary
                    disabled={
                      saveS3ConfigurationsLoading ||
                      updateS3ConfigurationsLoading
                    }
                    text={
                      saveS3ConfigurationsLoading ||
                      updateS3ConfigurationsLoading
                        ? messages.button.wait
                        : messages.button.save
                    }
                    startIcon={
                      (saveS3ConfigurationsLoading ||
                        updateS3ConfigurationsLoading) && (
                        <img src={DisableLoader} alt="Loader" />
                      )
                    }
                  />
                </Box>
              )}
            </SectionContent>
          </form>
        </Box>
      </SectionContent>
    </>
  );
};
