import { AdvanceDocumentEditor } from "@modules/templexConfiguration/components";
import {
  useUpdateTemplate,
  useUploadTemplate,
} from "@modules/templexConfiguration/services/configureTemplateService";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Slide, StyledEngineProvider, useTheme } from "@mui/material";
import {
  Box,
  Button,
  FileDragDrop,
  Icon,
  Switch,
  Typography,
} from "@ntpkunity/controls";
import Snackbar from "@shared/components/Snackbar";
import { FC, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UploadTemplatePageStyle } from "./uploadTemplatePageStyle";

const UploadTemplatePage: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { template_name } = useParams();
  const [checked, setChecked] = useState(true);
  const [search, setSearch] = useState(
    template_name === "template_name" ? "" : template_name
  );
  const [toastData, setToastData] = useState({
    toastLoading: false,
    toastMessage: ""
  })
  const [base64string, setBase64String] = useState<string | ArrayBuffer>("");
  const { mutate: uploadTemplate } = useUploadTemplate();

  const [version, setVersion] = useState("");

  const OnFilesSelect = (event) => {
    const file = event.target.files[0];
    convertFileToBase64(file);
  };

  const OnFilesDrag = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    convertFileToBase64(file);
  };

  const convertFileToBase64 = (file) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      setBase64String(base64String);
    };

    reader.readAsDataURL(file);
  };

  // @ts-ignore
  const finalbase64string = base64string.substring(
    // @ts-ignore
    base64string.indexOf(",") + 1
  );

  const updateFileNameString = (search, version) => {
    const lastIndex = search.lastIndexOf("_v");
    if (lastIndex !== -1) {
      const baseFilename = search.substring(0, lastIndex);
      const extension = search.substring(search.lastIndexOf("."));
      return baseFilename + "_" + version + extension;
    }
    const extensionIndex = search.lastIndexOf(".");
    const baseFilename = search.substring(0, extensionIndex);
    const extension = search.substring(extensionIndex);
    return baseFilename + "_" + version + extension;
  };

  // const { mutate: updateTemplate } = useUpdateTemplate(updateFileNameString(search,version));
  const { mutate: updateTemplate } = useUpdateTemplate(template_name);

  const data = {
    is_active: checked,
    is_deleted: false,
    template_name:
      template_name === "template_name"
        ? search
        : updateFileNameString(search, version),
    template_url: "string",
    storage_type: "S3",
    base64_html_template: "string",
    base64_file_template: finalbase64string,
    default: false,
  };

  function handleUploadTemplate(data) {
    if(data.template_name === "" || data.base64_file_template === ""){
      setToastData({toastLoading:true,toastMessage:"Either FileName or File Is Missing"})
      setTimeout(()=>{
        setToastData({toastLoading:false,toastMessage:""})
      },3000)
    }else{
      uploadTemplate(data, {
        onSuccess: (response) => {
          setSearch("");
          setVersion("");
          setToastData({toastLoading:true,toastMessage:"Template Uploaded Successfully"})
          setTimeout(()=>{
            setToastData({toastLoading:false,toastMessage:""})
          },3000)
        },
        onError: (error: any) => {},
      });
    }

  }

  function handleUpdateTemplate(data) {
    updateTemplate(data, {
      onSuccess: (response) => {
        setSearch("");
        setVersion("");
        setToastData({toastLoading:true,toastMessage:"Template Updated Successfully"})
        setTimeout(()=>{
          setToastData({toastLoading:false,toastMessage:""})
        },3000)
      },
      onError: (error: any) => {},
    });
  }

  return (
    <>
      <UploadTemplatePageStyle theme={theme}>
        <Box theme={theme} className="header-wrapper">
          <Button
            theme={theme}
            iconText={<Icon name="IconLeftArrow" />}
            onClick={() => navigate("/dock/home")}
          />
          <Box theme={theme} className="header-container">
            <Button
              theme={theme}
              secondary
              iconText={<Icon name="MoreIcon" />}
            />
            <Button
              theme={theme}
              primary
              text="Save Template"
              // onClick={() => handleUploadTemplate(data)}
              onClick={() => {
                template_name === "template_name"
                  ? handleUploadTemplate(data)
                  : handleUpdateTemplate(data);
              }}
              // onClick={() => navigate("/dock/configure-template-storage")}
            />
          </Box>
        </Box>
        <Box theme={theme} className="upload-container">
          <Box theme={theme} className="filter">
            <Box theme={theme} className="filter-wrapper">
              <Box theme={theme} className="filter-container">
                <Typography
                  theme={theme}
                  component="div"
                  className="sortby-text"
                >
                  Storage: S3
                </Typography>
                <KeyboardArrowDownIcon className="sortby-arrow" />
              </Box>
              {/* <Box theme={theme} className="filter-container">
                <Typography
                  theme={theme}
                  component="div"
                  className="sortby-texts"
                >
                  Default:
                </Typography>
                <KeyboardArrowDownIcon className="sortby-arrow" />
              </Box> */}
            </Box>
            {/* <Box theme={theme} className="switch-container">
              <Typography
                theme={theme}
                component="div"
                className="switch-status"
              >
                {checked ? "Enabled" : "Disabled"}
              </Typography>
              <Switch
                theme={theme}
                dynamic
                varient={"basic"}
                check={checked}
                onChange={() => setChecked(!checked)}
              />
            </Box> */}
          </Box>
          <Box theme={theme} className="input_container">
            <input
              type="text"
              placeholder="Type template name here..."
              value={search}
              className="template_input"
              onChange={(event) => setSearch(event.target.value)}
            />
            {/* {template_name !== "template_name" && (
              <input
                type="text"
                placeholder="Type version here..."
                value={version}
                className="version_input"
                onChange={(event) => setVersion(event.target.value)}
              />
            )} */}
          </Box>
          <Box theme={theme} className="upload-wrapper">
            <FileDragDrop
              theme={theme}
              allowMultiple={false}
              onChange={OnFilesSelect}
              onDrop={OnFilesDrag}
              hoverLabel="Drag and drop file, or browse"
            />
            <Button className="upload-button" text="Upload File" />
          </Box>
        </Box>
      </UploadTemplatePageStyle>
      <Snackbar
        theme={theme}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
        variant='success'
        open={toastData?.toastLoading ? toastData?.toastLoading : false}
        message={toastData?.toastMessage ? toastData?.toastMessage : ""}
        TransitionComponent={(props) => <Slide {...props} direction="up" />}
      />
    </>
  );
};

export default UploadTemplatePage;
