
import { ConfigureTemplateStoragePage, DocumentEditor } from "@modules/templexConfiguration";
import { AdvanceDocumentEditor } from "@modules/templexConfiguration/components/documentEditor/advanceDocumentEditorComp";
import ConfigureTemplatePage from "@modules/templexConfiguration/pages/configureTemplate/configureTemplatePage";
import CreateTemplatePage from "@modules/templexConfiguration/pages/createTemplatePage/createTemplatePage";
import TemplexHomePage from "@modules/templexConfiguration/pages/templexHomePage/templexHomePage";
import TemplateSetupsTabPage from "@modules/templexConfiguration/pages/templexSetupsTabPage/templexSetupsTabPage";
import UploadTemplatePage from "@modules/templexConfiguration/pages/uploadTemplate/uploadTemplatePage";
import { FC, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import '../root.component.css';
import { APP_ROUTES } from "./path";

export const Router: FC = () => {
  return (
    <Routes>
      {/* <Route path={APP_ROUTES.CONFIGURE_TEMPLATE} element={<ConfigureTemplatePage />} /> */}
      {/* <Route path={APP_ROUTES.DOC_EDITOR} element={<DocumentEditor />} /> */}
      {/* <Route path={APP_ROUTES.DOC_EDITOR} element={<AdvanceDocumentEditor />} /> */}
      {/* <Route path={APP_ROUTES.TEPLATE_SETUP_TABS} element={<TemplateSetupsTabPage />} /> */}
      <Route path={APP_ROUTES.TEMPLEX_HOME_PAGE} element={<TemplexHomePage />} />
      <Route path={APP_ROUTES.CREATE_TEMPLATE_PAGE} element={<CreateTemplatePage />} />
      <Route path={APP_ROUTES.UPLOAD_TEMPLATE} element={<UploadTemplatePage />} />
      <Route path={APP_ROUTES.CONFIGURE_TEMPLATE_STORAGE} element={<ConfigureTemplateStoragePage />} />
    </Routes>
  );
};