import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const UploadTemplatePageStyle = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  ".header-wrapper": {
    display: "flex",
    width: "100%",
    alignItems: "center",
    background: "#F5F5F7",
    zIndex: 1,
    justifyContent: "space-between",
    padding: "30px 40px 20px 40px",
    ".header-container": {
      display: "flex",
      alignItems: "center",
      gap: 10,
    },
  },
  ".upload-container": {
    padding: "40px",
    margin: "20px 40px 20px 56px",
    background: "#FFFFFF",
    borderRadius: "16px",
    ".filter": {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      ".filter-wrapper": {
        display: "flex",
        alignItems: "center",
        gap: "14px",
        width: "100%",
        ".filter-container": {
          display: "flex",
          alignItems: "center",
          minWidth: "80px",
          padding: "4px 8px 4px 16px",
          justifyContent: "space-between",
          cursor: "pointer",
          borderRadius: "24px",
          border: "1px solid #D8DAE5",
          ".sortby-text": {
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            display: "flex",
            alignItems: "center",
            color: "#515466",
          },
          ".sortby-arrow": {
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "24px",
            color: "#515466",
          },
        },
      },
      ".switch-container": {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        ".switch-status": {
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          color: "#515466",
        },
      },
    },
    ".input_container": {
      display: "flex",
      ".template_input":{
        "&:focus": {
          outline: "none",
        },
        "::placeholder": { color: "#D8DAE5" },
        marginTop: "10px",
        width: "100%",
        height: "72px",
        border: "none",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "36px",
        lineHeight: "48px",
        padding: "0px 10px",
        color: "#D8DAE5",
      },
      ".version_input":{
        "&:focus": {
          outline: "none",
        },
        "::placeholder": { color: "#D8DAE5" },
        marginTop: "10px",
        width: "100%",
        height: "72px",
        border: "none",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "700",
        fontSize: "26px",
        lineHeight: "48px",
        padding: "0px 10px",
        color: "#D8DAE5",
      },
    },
    ".upload-wrapper": {
      position: "relative",
      opacity: "0.9",
    },
    ".upload-button": {
      zIndex: "-1",
      position: "absolute",
      border: "1px solid #9A9EB2",
      padding: "8px 10px",
      gap: "10px",
      width: "130px",
      height: " 40px",
      borderRadius: "8px",
      backgroundColor: "white",
      bottom: "8%",
      left: "50%",
      transform: "translate(-50%,12%)",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      color: "#12131A",
      textTransform: "none",
    },
  },
}
// }
));
