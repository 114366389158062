// import { ServiceConstants } from "@shared/constants";
import { BASE_URL } from "@shared/constants";
import Http from "@shared/helper/http-api";
import { token } from "@shared/utils";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

const SAVE_TEMPLATE_CONFIGURATION =
  "documents/configuration/template/create-template-configuration";
const UPLOAD_FILE_AS_BAS64 = "documents/configuration/upload-file-as-base64";
const GET_ALL_TEMPLATES = "documents/configuration/template/";
const UPLOAD_TEMPLATE = "documents/configuration/template/";
const GETBASE64_TEMPLATE =
  "documents/configuration/template/get-base64-template";
const UPDATE_TEMPLATE = "documents/configuration/template/update-by-name/";
const GET_TEMPLATE_CONFIGURATION =
  "documents/configuration/template/get-template-configuration";
const UPDATE_TEMPLATE_CONFIGURATION =
  "documents/configuration/template/update-template-configuration";
const GET_ALL_TEMPLATE_CONFIGURATION_FILTER =
  GET_TEMPLATE_CONFIGURATION + "/filter";

export const useUploadFileAsBase64 = (): any => {
  const { data, isLoading, mutate, error } = useMutation<any, any>(
    (body: any) => {
      body.tenant_id = token.tenant_id;
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      return apiService.post<any>(UPLOAD_FILE_AS_BAS64, body);
    }
  );
  return { data, isLoading, mutate, error: error?.message };
};

export const useGetAllTemplates = () => {
  const { data, error } = useQuery(
    GET_ALL_TEMPLATES,
    () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      return apiService.get<any>(GET_ALL_TEMPLATES, "");
    },
    { refetchOnWindowFocus: false }
  );
  return { data, error };
};

export const useUploadTemplate = () => {
  const { data, isLoading, mutate, error } = useMutation<any, any>(
    (body: any) => {
      body.tenant_id = token.tenant_id;
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      return apiService.post<any>(UPLOAD_TEMPLATE, body);
    }
  );
  return { data, isLoading, mutate, error: error?.message };
};

export const useUpdateTemplate = (template_name) => {
  const { data, isLoading, mutate, error } = useMutation<any, any>(
    (body: any) => {
      body.tenant_id = token.tenant_id;
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      return apiService.patch<any>(UPDATE_TEMPLATE + template_name, body);
    }
  );
  return { data, isLoading, mutate, error: error?.message };
};

export const useGetBase64Template = () => {
  const { data, isLoading, mutate, error } = useMutation<any, any>(
    (body: any) => {
      body.tenant_id = token.tenant_id;
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      return apiService.post<any>(GETBASE64_TEMPLATE, body);
    }
  );
  return { data, isLoading, mutate, error: error?.message };
};

export const useSaveTemplateConfiguration = (): any => {
  const queryClient = useQueryClient();
  const { data, isLoading, mutate, error } = useMutation<any, any>(
    (body: any) => {
      body.tenant_id = token.tenant_id;
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      return apiService.post<any>(SAVE_TEMPLATE_CONFIGURATION, body);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_TEMPLATE_CONFIGURATION);
        queryClient.invalidateQueries(GET_ALL_TEMPLATE_CONFIGURATION_FILTER);
      },
    }
  );
  return { data, isLoading, mutate, error: error?.message };
};

export const useUpdateTemplateConfiguration = (): any => {
  const queryClient = useQueryClient();
  const { data, isLoading, mutate, error } = useMutation<any, any>(
    (body: any) => {
      body.tenant_id = token.tenant_id;
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      return apiService.patch<any>(UPDATE_TEMPLATE_CONFIGURATION, body);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_TEMPLATE_CONFIGURATION);
        queryClient.invalidateQueries(GET_ALL_TEMPLATE_CONFIGURATION_FILTER);
      },
    }
  );
  return { data, isLoading, mutate, error: error?.message };
};

export const useGetTemplateConfigurations = () => {
  const { data, error } = useQuery(
    GET_TEMPLATE_CONFIGURATION,
    () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      return apiService.get<any>(
        GET_TEMPLATE_CONFIGURATION + `/${token.tenant_id}`
      );
    },
    { refetchOnWindowFocus: false }
  );
  return { data, error };
};

export const useGetTemplateConfigurationFilter = (columnFilters: string) => {
  const { data, error } = useQuery(
    GET_ALL_TEMPLATE_CONFIGURATION_FILTER,
    () => {
      const apiService = Http.createConnection({
        baseUrl: BASE_URL,
      });
      return apiService.get<any>(
        GET_ALL_TEMPLATE_CONFIGURATION_FILTER +
          `/${token.tenant_id}?`.concat(columnFilters)
      );
    },
    { keepPreviousData: true }
  );
  return { data, error };
};
