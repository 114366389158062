import { useGetAllTemplates, useGetBase64Template } from "@modules/templexConfiguration/services/configureTemplateService";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SettingsIcon from "@mui/icons-material/Settings";
import { useTheme } from "@mui/material";
import {
  Box,
  Button,
  CircleLoader,
  Grid,
  Icon,
  Switch,
  Typography,
} from "@ntpkunity/controls";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Templex from "../../../../assets/Templex.png";
import { TemplexHomePageStyle } from "./templexHomePageStyle";

const TemplexHomePage: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [staticTemplates, setStaticTemplates] = useState([]);
  const { data } = useGetAllTemplates();
  const [isLoading,setIsLoading] = useState(false)
  const {mutate: getBase64Templete} = useGetBase64Template()

  const handleEditTemplate = (e:any)=>{
    const data = {template_name:e?.template_name}
    //@ts-ignore
      getBase64Templete(data,{
        onSuccess: (response) => {
          const byteCharacters = atob(response);
          const byteArrays = [];
          for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }
          const blob = new Blob(byteArrays, { type: 'application/octet-stream' });

          // Create a download link
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = e.template_name;

          // Programmatically trigger the download
          link.click();

          // Clean up the URL object
          URL.revokeObjectURL(link.href);
          navigate({ pathname: `/dock/upload/${e?.template_name}` })
        },
        onError: (error: any) => {},
      });
  }

  useEffect(() => {
    setIsLoading(true)
    if (data !== undefined) {
      setStaticTemplates(data);
      setIsLoading(false)
    }
  }, [data]);

  return (
    <>
      <TemplexHomePageStyle theme={theme}>
        <Box theme={theme} className="header-wrapper">
          <img
            src="https://docs-dev.netsolapp.io/dock/docs/64x64.png"
            alt="Templex"
            onClick={() => window.location.reload()}
          />
          <Box theme={theme} className="header-container">
            <Button theme={theme} secondary iconText={<SettingsIcon />} />
            <Button
              theme={theme}
              secondary
              onClick={() => navigate("/dock/upload/template_name")}
              startIcon={<Icon name="UploadIcon" className="update-icon" />}
              text="Upload Template"
            />
            <Button
              theme={theme}
              primary
              startIcon={<Icon name="AddIcon" />}
              text="Create Template"
              onClick={() => navigate("/dock/create")}
            />
          </Box>
        </Box>
        <Box theme={theme} className="search-wrapper">
          <Box theme={theme} className="search-container">
            <Icon name="SearchIcon" />
            <input
              type="text"
              placeholder="Type your search here..."
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </Box>
          <Box theme={theme} className="filter-container">
            <Typography theme={theme} component="div" className="sortby-text">
              Sort By:
            </Typography>
            <KeyboardArrowDownIcon className="sortby-arrow" />
          </Box>
        </Box>
        {
            isLoading ? <CircleLoader theme={theme}/> :
          <Box className="templates-wrapper" theme={theme}>
          <Grid theme={theme} container spacing={2}>
            {staticTemplates
              ?.filter((e) =>
                e?.template_name.toLowerCase().includes(search.toLowerCase())
                )
              .map((e) => (
                <Grid
                  theme={theme}
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  key={e?.id}
                  >
                  <Box theme={theme} className="templates-container">
                    <Box theme={theme} className="templates-container-header">
                      <Typography
                        theme={theme}
                        component="div"
                        noWrap={true}
                        className="template-name-text"
                        >
                        {e?.template_name}
                      </Typography>
                      {/* <Typography
                        theme={theme}
                        component="div"
                        className="template-default-text"
                        >
                        Default
                      </Typography> */}
                    </Box>
                    <Typography
                      theme={theme}
                      component="div"
                      noWrap={true}
                      className="template-date"
                    >
                      Last Updated: {e?.updated_at}
                    </Typography>
                    <Box theme={theme} className="templates-container-footer">
                      <Box theme={theme} className="switch-container">
                        <Switch
                          theme={theme}
                          dynamic
                          varient={"basic"}
                          check={e?.is_active}
                          />
                        <Typography
                          theme={theme}
                          component="div"
                          className="switch-status"
                          >
                          {e?.is_active ? "Enabled" : "Disabled"}
                        </Typography>
                      </Box>
                      <Box theme={theme} className="tools-container">
                        <Button
                          theme={theme}
                          iconText={<Icon name="EditIcon" />}
                          onClick={()=> handleEditTemplate(e)}
                        />
                        {/* <Button
                          theme={theme}
                          iconText={<Icon name="DeleteIcon" />}
                        /> */}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>
            }
      </TemplexHomePageStyle>
    </>
  );
};

export default TemplexHomePage;
