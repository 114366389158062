import { Editor } from "@tinymce/tinymce-react";
import React from "react";
import tinymce from "tinymce/tinymce";

import "tinymce/icons/default";
import "tinymce/models/dom/model";
import "tinymce/plugins/advlist";
import "tinymce/plugins/anchor";
import "tinymce/plugins/autolink";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/autosave";
import "tinymce/plugins/charmap";
import "tinymce/plugins/code";
import "tinymce/plugins/codesample";
import "tinymce/plugins/directionality";
import "tinymce/plugins/emoticons";
import "tinymce/plugins/emoticons/js/emojis";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/help";
import "tinymce/plugins/image";
import "tinymce/plugins/importcss";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/media";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/preview";
import "tinymce/plugins/quickbars";
import "tinymce/plugins/save";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/table";
import "tinymce/plugins/template";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/wordcount";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/themes/silver";

import { useUploadFileAsBase64 } from "@modules/templexConfiguration/services/configureTemplateService";
import { useTheme } from "@mui/material";
import { Button, Typography } from "@ntpkunity/controls";
import { IDesignerConfiguration } from "@shared/typings";
import { useForm } from "react-hook-form";
import { TableControlModal } from "./tableModal";
import { TemplateS3Configuration } from "./templateS3Configuration";

export function AdvanceDocumentEditor(props: any) {
  const [data, setData] = React.useState("");
  const { init, ...rest } = props;
  const theme = useTheme();
  const [header, setHeader] = React.useState([]);
  const [headerValues, setHeaderValues] = React.useState([]);
  const [footer, setFooter] = React.useState([]);
  const { mutate: uploadFileAsBase64 } = useUploadFileAsBase64();

  const customTableHtml = `<table border="1" cellspacing="0">
   <tbody>
    <tr>
    ${header
      .map(
        (e) =>
          `<td valign="top" width="105">
          <p class="MsoNormal" align="center"><span class="15"><strong>${
            e !== undefined ? `${e}` : ""
          }</strong></span></p>
          </td>`
      )
      .join("")}
    </tr>
    <tr>
    <td colspan=${header.length} valign="top">
    <p class="MsoNormal" align="center"><strong><span class="15">{%tr for items in Planitems%}</span></strong></p>
    </td>
    </tr>
    <tr>
    ${headerValues
      .map(
        (e) =>
          `<td valign="top" width="105">
        <p class="MsoNormal" align="center"><span class="15">${
          e !== undefined ? `{{items.${e}}}` : ""
        }</span></p>
        </td>`
      )
      .join("")}
    </tr>
    <tr>
    <td colspan=${header.length} valign="top">
    <p class="MsoNormal" align="center"><strong><span class="15">{%tr endfor%}</span></strong></p>
    </td>
    </tr>
    <tr>
    ${footer
      .map(
        (e) =>
          `<td valign="top" width="105">
        <p class="MsoNormal" align="center"><span class="15">${
          e === "Total"
            ? `<strong>${e}</strong>`
            : e !== undefined
            ? `{{${e}}}`
            : ""
        }</span></p>
        </td>`
      )
      .join("")}
    </tr>
    </tbody>
    </table>`;

  const handleEditorChange = (e: any) => {
    // Table Do Not Exits
    setData(e);
    // Table Exists
    if (e.search("table") !== -1) {
      var tablePrefix = e.indexOf("<table");
      var tablePostfix = e.indexOf("</table>");
      if (header.length == 0) {
        if (e.substring(0, 6) == "<table") {
          alert("no info added in table designer...");
          setData("<p></p>");
        } else if (e.substring(0, 6) !== "<table") {
          alert("no info added in table designer...");
          setData(e.substring(0, tablePrefix) + e.substring(tablePostfix + 8));
        }
      } else {
        setData(
          e.substring(0, tablePrefix) +
            customTableHtml +
            e.substring(tablePostfix + 8)
        );
      }
    }
  };

  const Export2Word = async (htmlString: any) => {
    const data = { file_name: "Template.docx", file_data: htmlString };
    uploadFileAsBase64(data);
  };

  const designerConfigurationForm = useForm<IDesignerConfiguration>();
  const onDesignerConfigurationSubmit = (data: any): void => {
    console.log(data);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {/* <TemplateS3Configuration
        designerConfigurationForm={designerConfigurationForm}
        onDesignerConfigurationSubmit={onDesignerConfigurationSubmit}
      /> */}
      <div style={{ position: "relative" }}>
        <button
          onClick={handleOpen}
          style={{
            position: "absolute",
            zIndex: "9",
            left: "348px",
            top: "18px",
            border: "none",
            background: "white",
            cursor: "pointer",
          }}
        >
          Table Info
        </button>
        <Editor
          onEditorChange={handleEditorChange}
          value={data}
          init={{
            ...init,
            skin: false,
            height: 600,
            table_grid: true,
            content_css: true,
            toolbar_mode: "sliding",
            selector: "textarea#open-source-plugins",
            menubar: "file edit view insert format tools table help",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }",
            plugins:
              "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount charmap quickbars emoticons",
            toolbar:
              "undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl",
            file_picker_callback: (cb) => {
              const input = document.createElement("input");
              input.setAttribute("type", "file");
              input.setAttribute("accept", "image/*");
              input.addEventListener("change", (e: any) => {
                const file = e.target.files[0];
                const reader: any = new FileReader();
                reader.addEventListener("load", () => {
                  const id = "blobid" + new Date().getTime();
                  const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                  const base64 = reader.result.split(",")[1];
                  const blobInfo = blobCache.create(id, file, base64);
                  blobCache.add(blobInfo);
                  cb(blobInfo.blobUri(), { title: file.name });
                });
                reader.readAsDataURL(file);
              });
              input.click();
            },
          }}
          {...rest}
        />
        {/* <Button
          type="submit"
          theme={theme}
          primary
          text="Upload Template"
          onClick={() => Export2Word(data)}
          style={{ marginTop: "20px" }}
        /> */}
      </div>
      <TableControlModal
        handleClose={handleClose}
        handleOpen={handleOpen}
        open={open}
        header={header}
        headerValues={headerValues}
        footer={footer}
        setHeader={setHeader}
        setHeaderValues={setHeaderValues}
        setFooter={setFooter}
      />
    </>
  );
}
